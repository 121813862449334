<script setup>

let windowObj;
let backToTop;
let bottomValue;
const scrollTime = 800;
const scrollDistance = 1200;

// State to track whether the sticky nav bar is present and visible on mobile
const isSticky = ref(false);
const isStickyVisible = ref(false);

const offsetAnchor = () => {
  windowObj.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const toggleBackToTop = () => {
  if (windowObj.pageYOffset > scrollDistance) {
    backToTop.style.display = 'block';

    if (isSticky.value && isStickyVisible.value) {
      backToTop.style.bottom = bottomValue || '100px'; // Default value
    } else {
      backToTop.style.bottom = '40px'; // Reset to default position
    }
  } else {
    backToTop.style.display = 'none';
  }
};

onMounted(() => {
  windowObj = window;
  backToTop = document.querySelector('.scroll-back-top');
  // Check if sticky nav bar is present on mount
  const stickyNav = document.querySelector('#sub-menu-footer') || document.querySelector('.floating-mobile');
  if (stickyNav) {
    isSticky.value = true;
    // Check if sticky nav bar is visible on mobile
    if (window.innerWidth < 768 && stickyNav.offsetTop < scrollDistance) {
      isStickyVisible.value = true;
    }
  }
  // This is desktop sticky footer for course pages
  const desktopStickyNav = document.querySelector('.floating-container');
  if (desktopStickyNav) {
    isSticky.value = true;
    isStickyVisible.value = true;
    if (window.innerWidth > 992 && stickyNav.offsetTop < scrollDistance) {
      bottomValue = '120px';
    }
  }

  backToTop.querySelector('a').addEventListener('click', (event) => {
    event.preventDefault();
    offsetAnchor();
  });

  windowObj.addEventListener('scroll', () => {
    requestAnimationFrame(toggleBackToTop); // Throttle scroll event
  });

  windowObj.addEventListener('resize', () => {
    requestAnimationFrame(toggleBackToTop); // Throttle resize event
  });

  toggleBackToTop();
});

onUnmounted(() => {
  windowObj.removeEventListener('scroll', toggleBackToTop);
  windowObj.removeEventListener('resize', toggleBackToTop);
});
</script>

<template>
  <span class="d-inline-block scroll-top-wrapper">
    <span class="scroll-back-top">
      <a><i class="icon-arrow-up"></i></a>
    </span>
  </span>
</template>

<style lang="scss">
.scroll-top-wrapper {
  position: absolute;

  .scroll-back-top {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: white;
    opacity: 0.8;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.06);
    z-index: 1029;

    a {
      display: block;
      height: 48px;
    }

    .icon-arrow-up {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      color: #666666;
      font-size: 20px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
